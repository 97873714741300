// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import NextLink, { type LinkProps } from 'next/link'
import { forwardRef } from 'react'

// eslint-disable-next-line import/exports-last
export type DynamicLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  Omit<LinkProps, 'href'> & { noCache?: boolean; href: string }

const Link = forwardRef<HTMLAnchorElement, DynamicLinkProps>(function Link({ href: href_, noCache, ...props }, ref) {
  const href = noCache ? `${href_}?${encodeURIComponent(Math.random().toString(36).substring(7))}` : href_
  return <NextLink href={href} ref={ref} {...props} />
})

export default Link
